






import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  metaInfo: {
    title: "My Account",
  },
})
export default class Profile extends Vue {}
